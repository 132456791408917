@charset "utf-8";

html, body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, p, blockquote, q, tr, th, td, caption, address, span, img, iframe, em, strong, small, article, aside, figcaption, figure, footer, header, main, nav, section, time {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    box-sizing: border-box;
}

html {
    overflow-y: scroll;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    text-size-adjust: 100%;
}

article, aside, figcaption, figure, footer, header, main, nav, section, time {
    display: block;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: none;
}

a {
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

address, caption, cite, code, dfn, em, strong, th, var {
    font-style: normal;
    font-weight: normal;
}

caption, th {
    text-align: left;
}

h1, h2, h3, h4, h5, h6 {
    font-size: 100%;
    font-weight: normal;
}

img {
    font-size: 0;
    line-height: 0;
    vertical-align: top;
}

sup {
    vertical-align: text-top;
}

sub {
    vertical-align: text-bottom;
}

table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

input, textarea {
    margin: 0;
    padding: 0;
}

input, select {
    vertical-align: middle;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

/*to enable resizing for IE*/
input, textarea, select {
    *font-size: 100%;
}